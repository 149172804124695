import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { IPaginationRequest } from '../../../api/models';
import { KTIcon } from '../../../_metronic/helpers';
import { Pagination } from '../../helpers/pagination/Pagination';
import { FindCreativesQuery, UserRole } from '../../../api/v2/gql/gen/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_FIND_CREATIVES } from '../../../api/v2/gql/campaigns';
import { client } from '../../../api/v2/gql/client';
import { MUTATION_DELETE_CREATIVE, MUTATION_DUPLICATE_CREATIVE, MUTATION_UPDATE_CREATIVE } from '../../../api/v2/gql/creatives';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../../app/modules/auth';

export const CreativesPage = () => {
  const { currentUser } = useAuth();
  const intl = useIntl();

  const [pagination, setPagination] = useState<IPaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  });

  const [totalCount, setTotalCount] = useState(0);
  const [disableWarningModalShow, setDisableWarningModalShow] = useState(false);
  const [disableWarningModalShowed, setDisableWarningModalShowed] = useState(false);
  const [creatives, setCreatives] = useState<FindCreativesQuery['findCreatives']['creatives'][number][]>([]);

  const [fetchCreatives] = useLazyQuery(QUERY_FIND_CREATIVES);
  const [deleteCreative] = useMutation(MUTATION_DELETE_CREATIVE);
  const [duplicateCreative] = useMutation(MUTATION_DUPLICATE_CREATIVE);

  // Получение advID из текущего пользователя
  const advID = currentUser?.role === UserRole.Admin ? null : currentUser?.advertiserProfile?.advertiserID;

  // Функция загрузки креативов
  const update = useCallback(async () => {
    if (!currentUser || advID === undefined) return;

    try {
      const { data } = await fetchCreatives({
        variables: {
          advID: advID,
          pagination: {
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
          },
        },
      });

      if (data) {
        setCreatives(data.findCreatives.creatives);
        setTotalCount(data.findCreatives.pageInfo.totalCount);
      }
    } catch (error) {
      console.error('Ошибка загрузки креативов:', error);
    }
  }, [pagination, advID, currentUser]);

  useEffect(() => {
    if (currentUser && advID !== undefined) {
      update();
    }
  }, [pagination, advID, currentUser]);

  const handleCopy = async (creative) => {
    await duplicateCreative({
      variables: { id: creative.id },
    });
    update();
  };

  const handleDelete = async (creative) => {
    await deleteCreative({
      variables: { id: creative.id },
    });
    setCreatives((prev) => prev.filter((e) => e.id !== creative.id));
  };

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CREATIVES' })}</PageTitle>

      <Modal
        className='modal fade'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={disableWarningModalShow}
        dialogClassName='modal-md'
        aria-hidden='true'
        onHide={() => {
          setDisableWarningModalShow(false);
          setDisableWarningModalShowed(true);
        }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Do you want to disable creative?</h5>
          </div>
          <div className='modal-body'>This action will disable creative in all campaigns</div>
          <div className='modal-footer'>
            <button
              id='submit'
              type='button'
              className='btn btn-primary'
              onClick={(e) => {
                e.preventDefault();
                setDisableWarningModalShow(false);
                setDisableWarningModalShowed(true);
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>

      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            <div className='card-body py-3 container'>
              <table className='table align-middle gs-0' style={{ marginBottom: '0' }}>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 w-60px'>Status</th>
                    <th className='w-200px'>Creatives</th>
                    <th className='w-200px'>Creative data</th>
                    <th className='w-200px text-end pe-4'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {creatives.map((creative) => (
                    <tr key={creative.id}>
                      <td>
                      <div className='d-flex align-items-center'>
                      <div className="form-check form-switch form-check-custom form-check-solid justify-content-start">
                        <input className='form-check-input w-35px h-20px ms-4' type='checkbox'
                          checked={creative.enabled}
                          onChange={async () => {
                            if (!disableWarningModalShowed) {
                              setDisableWarningModalShow(true);
                              return;
                            }

                            setCreatives((prev) =>
                              prev.map((e) =>
                                e.id === creative.id ? { ...creative, enabled: !creative.enabled } : e
                              )
                            );

                            await client.mutate({
                              mutation: MUTATION_UPDATE_CREATIVE,
                              variables: {
                                id: creative.id,
                                input: { enabled: !creative.enabled },
                              },
                            });
                          }}
                        />
                        </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {creative.imgRurl && (
                            <div>
                              <span className='symbol-label'>
                                <img src={creative.imgRurl} style={{ maxWidth: '100px' }} className='h-50px rounded align-self-center me-2' alt='' />
                              </span>
                            </div>
                          )}
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {creative.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>{creative.type}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a href={creative.rurl ?? undefined} className='text-dark fw-bold text-hover-primary d-block fs-6'>
                          {creative.rurl?.length ?? 0 >= 60 ? `${creative.rurl!.substring(0, 60)}...` : creative.rurl}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7' style={{
                        maxHeight: "3.6em",
                        lineHeight: "1.8em",
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                        overflow: "hidden",
                      }}>
                        {[creative.title, creative.description]
                          .filter((e) => e ? e.trim().length > 0 : '')
                          .join(' ')}
                      </span>
                      </td>
                      <td className='text-end pe-4'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' to={`/creatives/${creative.id}`}>
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>
                          <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={() => handleCopy(creative)}>
                            <KTIcon iconName='copy' className='fs-3' />
                          </a>
                          <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={() => handleDelete(creative)}>
                            <KTIcon iconName='trash' className='fs-3' />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination className='mt-10 mb-5' currentPage={pagination.pageNumber} totalCount={totalCount} pageSize={pagination.pageSize} onPageChange={(page) => setPagination({ ...pagination, pageNumber: page })} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreativesPage;