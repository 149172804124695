import { useState, useEffect, ReactNode, useCallback, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { PageTitle } from '../../../_metronic/layout/core'
import Select, { SingleValue } from 'react-select'
import { AlertType, generateAlert } from '../../helpers/alert'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import {
  EncodedFiltersHash,
  VersionedFilter,
  decodeFilters,
  encodeFilters,
} from '../../modules/filters/versioned_filters'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import CopyToClipboard from "react-copy-to-clipboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CreativeControl, CreativeControlDTO } from '../../modules/creatives/creativesControl'
import { CreativeTemplate } from '../../modules/creatives/creativeTemplate'
import clsx from 'clsx'
import { PushPreview } from '../creative/PushPreview'
import { CampaignType, ConnectionType, CreativeType, GetCampaignQuery, PayType, UserRole } from '../../../api/v2/gql/gen/graphql'
import { useFormik } from 'formik'
import { client } from '../../../api/v2/gql/client'
import { MUTATION_ATTACH_CREATIVE, MUTATION_CREATE_CAMPAIGN, MUTATION_DETACH_CREATIVE, MUTATION_UPDATE_CAMPAIGN, QUERY_GET_CAMPAIGN, QUERY_GET_CAMPAIGN_CREATIVES } from '../../../api/v2/gql/campaigns'
import { AdvPicker, AdvPickerResult } from '../../components/AdvPicker'
import { CountryMultiPicker, CountryMultyPickerResult } from '../../components/CountryMultiPicker'
import { RegionMultiPicker, RegionMultiPickerResult } from '../../components/RegionMultiPicker'
import { CityMultiPicker, CityMultiPickerResult } from '../../components/CityMultiPicker'
import { DeviceTypeMultiPicker, DeviceTypeMultiResult } from '../../components/DeviceTypeMultiPicker'
import { DeviceOSMultiPicker, DeviceOSMultiResult } from '../../components/DeviceOSMultiPicker'
import { DeviceBrowserMultiPicker, DeviceBrowserMultiResult } from '../../components/DeviceBrowserMultiPicker'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { MobileCarrierMultiPicker } from '../../components/MobileCarriersMultiPicker'
import { CampaignCategoryMultiPicker } from '../../components/CampaignCategoryMultiPicker'
import { CampaignCategoryTypePicker } from '../../components/CampaignCategoryTypePicker'
import { getUniqueIdWithPrefix } from '../../../_metronic/assets/ts/_utils'
import { MUTATION_CREATE_CREATIVE } from '../../../api/v2/gql/creatives'
import { CreativesTable } from './CreativesTable'
import { CampaignDisplayType } from './Campaigns'
import { Autorule, generateRuleString, parseExp } from '../../modules/autoRules/AutoRules'
import RuleComponent, { GetInitialAutorule } from '../../modules/autoRules/RuleComponent'
import { LanguageMultiPicker } from '../../components/LanguageMultiPicker'

export const CreateEditCampaign = () => {
  return (
    <>
      <CreateEditCampaignCard />
    </>
  )
}

const connectionOptions: { label: string, value: ConnectionType }[] = [
  { value: ConnectionType.Any, label: 'Any' },
  { value: ConnectionType.Wifi, label: 'Wi-Fi' },
  { value: ConnectionType.Mobile, label: 'Mobile' },
]

type TypeOption = {
  value: CampaignType,
  title: string,
  description: string,
  icon: ReactNode,
  allowedPayOptions: string[],
}
const typeOptions: TypeOption[] = [
  {
    value: CampaignType.Pops,
    title: "Pops",
    description: "New tab on click",
    allowedPayOptions: ["cpm", "cpa"],
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-window-stack" viewBox="0 0 16 16">
          <path d="M4.5 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M6 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
          <path d="M12 1a2 2 0 0 1 2 2 2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zM2 12V5a2 2 0 0 1 2-2h9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1m1-4v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8zm12-1V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v2z" />
        </svg>
      </span>
    ),
  },
  {
    value: CampaignType.Push,
    title: "Push",
    description: "Push notification on browser",
    allowedPayOptions: ["cpc", "cpa"],
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-right-dots" viewBox="0 0 16 16">
          <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
          <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
        </svg>
      </span>
    ),
  },
  {
    value: CampaignType.Banner,
    title: "Banner",
    description: "300x250",
    allowedPayOptions: ["cpm", "cpa"],
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-richtext" viewBox="0 0 16 16">
          <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208M5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
        </svg>
      </span>
    ),
  },
]

type PayOption = {
  value: PayType,
  title: string,
  description: string,
  icon: ReactNode,
}


const payOptions: PayOption[] = [
  {
    value: PayType.Cpm,
    title: "CPM",
    description: "Cost per 1000 impressions",
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
        </svg>
      </span>
    ),
  },
  {
    value: PayType.Cpc,
    title: "CPC",
    description: "Cost per 1 click",
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-index" viewBox="0 0 16 16">
          <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025" />
        </svg>
      </span>
    ),
  },
  {
    value: PayType.Cpa,
    title: "CPA",
    description: "Cost per conversion",
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-check" viewBox="0 0 16 16">
          <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
        </svg>
      </span>
    ),
  }
];

type CategoryOption = {
  value: string[]
  title: string
  isBlacklist: boolean,
  icon: string
}

const categoryOptions: CategoryOption[] = [
  {
    value: [],
    title: 'All',
    isBlacklist: false,
    icon: ' bi-soundwave',
  },
  {
    value: ['IAB25', 'IAB25-3'],
    title: 'Mainstream',
    isBlacklist: true,
    icon: ' bi-person-lines-fill',
  },
  {
    value: ['IAB25', 'IAB25-3'],
    title: 'Adult',
    isBlacklist: false,
    icon: ' bi-gender-ambiguous',
  },
]

type OperatorOption = {
  label: string,
  value: string,
}

const operatorOptions: OperatorOption[] = [
  {
    label: '=',
    value: '==',
  },
  {
    label: '<',
    value: '<',
  },
  {
    label: '>',
    value: '>',
  },
  {
    label: '<=',
    value: '<=',
  },
  {
    label: '>=',
    value: '>=',
  },
]


type CreateEditCampaignForm = {
  adv: AdvPickerResult,
  bid: string,
  bidder: boolean,
  blacklistIPRanges?: string,
  blacklistSSP?: string,
  blacklistSourceID?: string,
  category: string,
  convTrack: boolean,
  dailyLimitUniq: number,
  dailyLimitCls: number,
  dailyLimitCost: number,
  dailyLimitImp: number,
  enabled: boolean,
  lastModelImps: number,
  mlABtest: boolean,
  mlConv: boolean,
  mlConvMin: number,
  mlImpsStep: number,
  name: string,
  payType: PayType | undefined,
  roi: string,
  subsTTL: number,
  totalLimitCls: number,
  totalLimitCost: number,
  totalLimitImp: number,
  connectionType: ConnectionType,
  disableProxy: boolean,
  type: CampaignType | undefined,
  whitelistBrands?: string[],
  categories?: string[],
  categoriesIsBlack?: boolean,
  whitelistCities?: CityMultiPickerResult[],
  whitelistCountries?: CountryMultyPickerResult[],
  whitelistRegions?: RegionMultiPickerResult[],
  whitelistLanguageCodes?: string[],
  autorules?: Autorule[]
  autorulesEnabled: boolean,
  whitelistExec?: string,
  whitelistIPRanges?: string,
  whitelistSSP?: string,
  whitelistSourceID?: string,
}

type CreateEditCampaignFormSchema = {
  [K in keyof Required<CreateEditCampaignForm>]: Yup.Schema<CreateEditCampaignForm[K] extends Object ? any : CreateEditCampaignForm[K]>;
};


const sharedShape: CreateEditCampaignFormSchema = {
  type: Yup.string().oneOf(Object.values(CampaignType)),
  adv: Yup.object(),
  name: Yup.string().min(3, "Name is too short").max(50, "Name is too big"),
  bid: Yup.string().test("must-be-positive", "Bid must be positive", (value) => value ? !Number.isNaN(parseFloat(value)) && parseFloat(value) > 0 : true),
  roi: Yup.string().test("must-be-positive", "Roi must be positive", (value) => value ? !Number.isNaN(parseFloat(value)) && parseFloat(value) > 0 : true),
  subsTTL: Yup.number().min(0, "SubsTTL must be positive"),
  dailyLimitUniq: Yup.number().min(0, "Must be positive"),
  dailyLimitCls: Yup.number().min(0, "Must be positive"),
  dailyLimitImp: Yup.number().min(0, "Must be positive"),
  dailyLimitCost: Yup.number().min(0, "Must be positive"),
  totalLimitCls: Yup.number().min(0, "Must be positive"),
  totalLimitCost: Yup.number().min(0, "Must be positive"),
  totalLimitImp: Yup.number().min(0, "Must be positive"),
  bidder: Yup.bool(),
  category: Yup.string(),
  convTrack: Yup.bool(),
  lastModelImps: Yup.number().min(0, "Must be positive"),
  enabled: Yup.bool(),
  connectionType: Yup.string().oneOf(Object.values(ConnectionType)),
  disableProxy: Yup.boolean(),
  blacklistIPRanges: Yup.string(),
  blacklistSourceID: Yup.string(),
  blacklistSSP: Yup.string(),
  whitelistBrands: Yup.array(),
  categories: Yup.array(),
  categoriesIsBlack: Yup.boolean(),
  whitelistCities: Yup.array(),
  whitelistCountries: Yup.array(),
  whitelistExec: Yup.string(),
  whitelistIPRanges: Yup.string(),
  whitelistRegions: Yup.array(),
  whitelistLanguageCodes: Yup.array(),
  mlABtest: Yup.bool(),
  mlConv: Yup.bool(),
  mlConvMin: Yup.number(),
  mlImpsStep: Yup.number(),
  autorules: Yup.array(),
  autorulesEnabled: Yup.bool(),
  payType: Yup.string().oneOf(Object.values(PayType)),
  whitelistSourceID: Yup.string(),
  whitelistSSP: Yup.string(),
}

const CreateEditCampaignCard = () => {
  const idStr = useParams().id;
  const id = idStr ? parseInt(idStr) : undefined;
  const { currentUser } = useAuth()

  const [exists, setExists] = useState<GetCampaignQuery['getCampaign'] | null>(null);
  const [findExists] = useLazyQuery(QUERY_GET_CAMPAIGN);

  useEffect(() => {
    const fetch = async () => {
      if (id) {
        const response = await findExists({
          variables: {
            id: id,
          }
        });
        if (response.data) {
          setExists(response.data.getCampaign)
        }
      }
    }

    fetch();
  }, [id])

  return (
    <>
      {id ?
        <>
          {exists ?
            <CreateEditCampaignFormWidget
              id={id}
              initialValues={{
                enabled: exists.enabled,
                adv: {
                  ...exists.advertiser.user,
                  advertiserProfile: exists.advertiser,

                  // FIXME (marcus): 22.07.24 fix balance requirements
                  balance: {
                    id: "",
                    amount: "",
                  },
                },
                bid: exists.bid,
                bidder: exists.bidder,
                category: exists.category,
                convTrack: exists.convTrack,
                dailyLimitUniq: exists.limitsFilter.dailyLimitUniq ?? 0,
                dailyLimitCls: exists.limitsFilter.dailyLimitCls ?? 0,
                dailyLimitCost: exists.limitsFilter.dailyLimitCost ?? 0,
                dailyLimitImp: exists.limitsFilter.dailyLimitImp ?? 0,
                lastModelImps: exists.lastModelImps,
                mlABtest: exists.mlABTest,
                mlConv: exists.mlConv,
                mlConvMin: exists.mlConvMin,
                mlImpsStep: exists.mlImpsStep,
                name: exists.name,
                payType: exists.payType,
                roi: exists.roi,
                subsTTL: exists.subsTTL,
                connectionType: exists.brandsFilter.connectionType,
                disableProxy: exists.brandsFilter.disableProxy,
                totalLimitCls: exists.limitsFilter.totalLimitCls ?? 0,
                totalLimitCost: exists.limitsFilter.totalLimitCost ?? 0,
                totalLimitImp: exists.limitsFilter.totalLimitImp ?? 0,
                type: exists.type,
                blacklistIPRanges: exists.ipRangeFilter.blacklistIPRanges?.join(','),
                whitelistIPRanges: exists.ipRangeFilter.whitelistIPRanges?.join(','),
                blacklistSourceID: exists.sourceFilter.blacklistSourceID?.join(','),
                blacklistSSP: exists.sourceFilter.blacklistSSP?.join(','),
                whitelistExec: exists.devicesFilter.whitelistExec ?? '',
                whitelistBrands: exists.brandsFilter.whitelistBrands ?? undefined,
                categories: ((exists.categoriesFilter.whitelistCategories?.length ?? 0) > 0 ? exists.categoriesFilter.whitelistCategories : exists.categoriesFilter.blacklistCategories) ?? undefined,
                categoriesIsBlack: (exists.categoriesFilter.blacklistCategories?.length ?? 0) > 0,
                whitelistRegions: exists.regionsFilter.whitelistRegions ?? undefined,
                whitelistCountries: exists.countriesFilter.whitelistCountries ?? undefined,
                whitelistCities: exists.citiesFilter.whitelistCities ?? undefined,
                whitelistLanguageCodes: exists.languageFilter?.whitelistLanguageCodes ?? undefined,
                whitelistSourceID: exists.sourceFilter.whitelistSourceID?.join(','),
                whitelistSSP: exists.sourceFilter.whitelistSSP?.join(','),
                autorulesEnabled: (exists.sourceAutorules?.length ?? 0) > 0,
                autorules: exists.sourceAutorules && exists.sourceAutorules.length > 0 ? exists.sourceAutorules.map((e) => {
                  return {
                    frontend_id: getUniqueIdWithPrefix('autorule'),
                    days: e.days,
                    metrics: parseExp(e.clickhouseExp),
                  } as Autorule
                }) : [GetInitialAutorule()],
              }}
            /> :
            <>Loading...</>}
        </> :
        <>
          <CreateEditCampaignFormWidget
            initialValues={{
              adv: {
                ...currentUser!,
                // FIXME (marcus): 22.07.24 fix balance requirements
                balance: {
                  id: "",
                  amount: "",
                },
              },
              bid: "0",
              bidder: true,
              category: "",
              convTrack: false,
              dailyLimitUniq: 0,
              dailyLimitCls: 0,
              dailyLimitCost: 0,
              dailyLimitImp: 0,
              enabled: true,
              lastModelImps: 0,
              mlABtest: false,
              mlConv: true,
              mlConvMin: 10,
              mlImpsStep: 10000,
              name: "",
              payType: undefined,
              roi: "0.4",
              subsTTL: 0,
              totalLimitCls: 0,
              totalLimitCost: 0,
              totalLimitImp: 0,
              connectionType: ConnectionType.Any,
              disableProxy: false,
              type: undefined,
              blacklistIPRanges: '',
              blacklistSourceID: '',
              blacklistSSP: '',
              whitelistBrands: [],
              categories: [],
              categoriesIsBlack: false,
              whitelistCities: [],
              whitelistCountries: [],
              whitelistExec: '',
              whitelistIPRanges: '',
              whitelistRegions: [],
              whitelistLanguageCodes: [],
              whitelistSourceID: '',
              whitelistSSP: '',
              autorulesEnabled: false,
              autorules: [GetInitialAutorule()],
            }}
          />
        </>}
    </>
  )
}

const CreateEditCampaignFormWidget = ({ initialValues, id }: { initialValues: CreateEditCampaignForm, id?: number }) => {
  const { currentUser } = useAuth()
  const intl = useIntl()
  const navigate = useNavigate();
  const canCreateCreative = useMemo(() => !id, [id])
  const [allExistsCreatives, setAllExistsCreatives] = useState<CampaignDisplayType['creatives'][number][]>([]);
  const [filteredExistsCreatives, setFilteredExistsCreatives] = useState<CampaignDisplayType['creatives'][number][]>([]);

  const updateExistsCreatives = useCallback(async () => {
    if (id) {
      const response = await client.query({
        query: QUERY_GET_CAMPAIGN_CREATIVES,
        variables: {
          id: id!,
          pagination: {
            pageNumber: 1,
            pageSize: 200,
          }
        }
      })
      if (response.data) {
        setAllExistsCreatives(response.data.getCampaign.creatives)
        setFilteredExistsCreatives(response.data.getCampaign.creatives)
      }
    }
  }, [id])

  useEffect(() => {
    updateExistsCreatives();
  }, [id])

  const [bidChanged, setBidChanged] = useState(false);
  const [creatives, setCreatives] = useState<(CreativeControlDTO & { internalID: string })[]>([]);
  const [customCategories, setCustomCategories] = useState<boolean>(categoryOptions.findIndex(e => JSON.stringify(e.value) === JSON.stringify(initialValues.categories)) === -1);
  const accordionRefs = {
    limits: useRef<HTMLButtonElement>(null),
    admin: useRef<HTMLButtonElement>(null),
    geoLanguage: useRef<HTMLButtonElement>(null),
    device: useRef<HTMLButtonElement>(null),
    autorules: useRef<HTMLButtonElement>(null),
    connection: useRef<HTMLButtonElement>(null),
    pushSubs: useRef<HTMLButtonElement>(null),
    source: useRef<HTMLButtonElement>(null),
    aiSetting: useRef<HTMLButtonElement>(null),
    other: useRef<HTMLButtonElement>(null),
  }

  const fieldsAccordions: {
    [K in keyof Required<CreateEditCampaignForm>]: React.RefObject<any> | null;
  } = {
    name: null,
    adv: null,
    bid: null,
    convTrack: null,
    type: null,
    payType: null,
    enabled: null,
    category: null,
    categories: null,
    categoriesIsBlack: null,
    dailyLimitCls: accordionRefs.limits,
    dailyLimitCost: accordionRefs.limits,
    dailyLimitImp: accordionRefs.limits,
    totalLimitCls: accordionRefs.limits,
    totalLimitCost: accordionRefs.limits,
    totalLimitImp: accordionRefs.limits,
    dailyLimitUniq: accordionRefs.limits,
    roi: accordionRefs.admin,
    bidder: accordionRefs.admin,
    whitelistCountries: accordionRefs.geoLanguage,
    whitelistRegions: accordionRefs.geoLanguage,
    whitelistCities: accordionRefs.geoLanguage,
    whitelistLanguageCodes: accordionRefs.geoLanguage,
    whitelistExec: accordionRefs.device,
    subsTTL: accordionRefs.pushSubs,
    whitelistSourceID: accordionRefs.source,
    whitelistSSP: accordionRefs.source,
    blacklistSSP: accordionRefs.source,
    blacklistSourceID: accordionRefs.source,
    mlConv: accordionRefs.aiSetting,
    mlConvMin: accordionRefs.aiSetting,
    mlImpsStep: accordionRefs.aiSetting,
    lastModelImps: accordionRefs.other,
    blacklistIPRanges: accordionRefs.connection,
    whitelistIPRanges: accordionRefs.connection,
    connectionType: accordionRefs.connection,
    disableProxy: accordionRefs.connection,
    whitelistBrands: accordionRefs.connection,
    mlABtest: accordionRefs.admin,
    autorules: accordionRefs.autorules,
    autorulesEnabled: accordionRefs.autorules,
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      try {
        await Yup.object().shape(sharedShape).validate(values);
      } catch (e) {
        if (e instanceof Yup.ValidationError && e.params?.path) {
          const accordion = fieldsAccordions[e.params?.path as string];
          if (accordion && accordion.current) {
            if ((accordion.current.className as string).indexOf('collapsed') > -1) {
              accordion.current?.click();
            }
          }
          formik.setFieldError(e.params.path as string, e.message);
          return;
        }
      }

      try {
        if (!id) {
          const response = await client.mutate({
            mutation: MUTATION_CREATE_CAMPAIGN,
            variables: {
              input: {
                enabled: true,
                advID: values.adv!.advertiserProfile.advertiserID,
                bid: values.bid!,
                bidder: values.bidder!,
                category: values.category!,
                blacklistSourceID: values.blacklistSourceID!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistSSP: values.blacklistSSP!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistIPRanges: values.blacklistIPRanges!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                convTrack: values.convTrack!,
                dailyLimitUniq: values.dailyLimitUniq,
                dailyLimitCls: values.dailyLimitCls!,
                dailyLimitCost: values.dailyLimitCost!,
                dailyLimitImp: values.dailyLimitImp!,
                totalLimitCls: values.totalLimitCls!,
                totalLimitCost: values.totalLimitCost!,
                totalLimitImp: values.totalLimitImp!,
                mlConv: values.mlConv!,
                mlABTest: values.mlABtest,
                lastModelImps: values.lastModelImps!,
                mlConvMin: values.mlConvMin!,
                mlImpsStep: values.mlImpsStep!,
                name: values.name!,
                payType: values.payType!,
                roi: values.roi!.toString(),
                subsTTL: values.subsTTL!,
                type: values.type!,
                connectionType: values.connectionType,
                disableProxy: values.disableProxy,
                whitelistExec: values.whitelistExec,
                whitelistSourceID: values.whitelistSourceID!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistSSP: values.whitelistSSP!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistIPRanges: values.whitelistIPRanges?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistBrands: values.whitelistBrands?.map((e) => e.trim()).filter(e => e.length > 0),
                whitelistCategories: values.categoriesIsBlack ? [] : values.categories?.map((e) => e.trim()).filter(e => e.length > 0),
                blacklistCategories: values.categoriesIsBlack ? values.categories?.map((e) => e.trim()).filter(e => e.length > 0) : [],
                whitelistCitiesIDs: values.whitelistCities?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistCountriesIDs: values.whitelistCountries?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistRegionsIDs: values.whitelistRegions?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistLanguageCodes: values.whitelistLanguageCodes?.map((e) => e.trim()).filter(e => e.length > 0),
                sourceAutorules: values.autorulesEnabled ? values.autorules?.map((e) => {
                  return {
                    clickhouseExp: generateRuleString(e.metrics),
                    days: e.days,
                  }
                }) : [],
              }
            },
          });
          if (response.data) {
            for (var i = 0; i < filteredExistsCreatives.length; i++) {
              await client.mutate({
                mutation: MUTATION_ATTACH_CREATIVE,
                variables: {
                  campaignID: response.data!.createCampaignFull.id,
                  creativeID: filteredExistsCreatives[i].id,
                },
              })
            }

            const campaignID = response.data.createCampaignFull.id;
            for (var i = 0; i < creatives.length; i++) {
              const creative = creatives[i]
              switch (formik.values.type) {
                case CampaignType.Banner:
                  const bannerResponse = await client.mutate({
                    mutation: MUTATION_CREATE_CREATIVE,
                    variables: {
                      input: {
                        enabled: true,
                        name: creative.name,
                        advID: values.adv!.advertiserProfile.advertiserID,
                        type: CreativeType.Banner,
                        description: creative.bannerDescription,
                        iconRurl: "",
                        imgRurl: creative.bannerImageUrl,
                        rurl: creative.rurl,
                        size: creative.bannerSize,
                        title: creative.bannerTitle,
                      },
                    },
                  });
                  if (bannerResponse.data) {
                    await client.mutate({
                      mutation: MUTATION_ATTACH_CREATIVE,
                      variables: {
                        campaignID: campaignID,
                        creativeID: bannerResponse.data.createCreative.id,
                      },
                    })
                  }
                  break;
                case CampaignType.Pops:
                  const popsResponse = await client.mutate({
                    mutation: MUTATION_CREATE_CREATIVE,
                    variables: {
                      input: {
                        enabled: true,
                        name: creative.name,
                        advID: values.adv!.advertiserProfile.advertiserID,
                        type: CreativeType.Pops,
                        rurl: creative.rurl,
                        description: "",
                        iconRurl: "",
                        imgRurl: "",
                        size: "",
                        title: "",
                      },
                    },
                  });
                  if (popsResponse.data) {
                    await client.mutate({
                      mutation: MUTATION_ATTACH_CREATIVE,
                      variables: {
                        campaignID: campaignID,
                        creativeID: popsResponse.data.createCreative.id,
                      },
                    })
                  }
                  break;
                case CampaignType.Push:
                  const pushResponse = await client.mutate({
                    mutation: MUTATION_CREATE_CREATIVE,
                    variables: {
                      input: {
                        enabled: true,
                        name: creative.name,
                        advID: values.adv!.advertiserProfile.advertiserID,
                        type: CreativeType.Push,
                        description: creative.pushDescription,
                        iconRurl: creative.pushIconUrl,
                        imgRurl: creative.pushImageUrl,
                        rurl: creative.rurl,
                        size: "",
                        title: creative.pushTitle,
                      },
                    },
                  });
                  if (pushResponse.data) {
                    await client.mutate({
                      mutation: MUTATION_ATTACH_CREATIVE,
                      variables: {
                        campaignID: campaignID,
                        creativeID: pushResponse.data.createCreative.id,
                      },
                    })
                  }
                  break;
              }
            }


            navigate(`/campaign/${response.data.createCampaignFull.id}`);
          }
        } else {
          const response = await client.mutate({
            mutation: MUTATION_UPDATE_CAMPAIGN,
            variables: {
              id: id!,
              input: {
                bid: values.bid,
                type: values.type,
                bidder: values.bidder,
                convTrack: values.convTrack,
                dailyLimitUniq: values.dailyLimitUniq,
                dailyLimitCls: values.dailyLimitCls,
                dailyLimitCost: values.dailyLimitCost,
                dailyLimitImp: values.dailyLimitImp,
                totalLimitCls: values.totalLimitCls,
                totalLimitCost: values.totalLimitCost,
                totalLimitImp: values.totalLimitImp,
                lastModelImps: values.lastModelImps,
                connectionType: values.connectionType,
                disableProxy: values.disableProxy,
                mlABTest: values.mlABtest,
                mlConv: values.mlConv,
                mlConvMin: values.mlConvMin,
                blacklistSSP: values.blacklistSSP?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistSourceID: values.blacklistSourceID?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistIPRanges: values.blacklistIPRanges?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                mlImpsStep: values.mlImpsStep,
                name: values.name,
                category: values.category,
                payType: values.payType,
                roi: values.roi ? values.roi.toString() : null,
                subsTTL: values.subsTTL,
                advID: values.adv?.advertiserProfile.advertiserID ?? currentUser?.advertiserProfile.advertiserID,
                whitelistExec: values.whitelistExec,
                whitelistSSP: values.whitelistSSP?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistSourceID: values.whitelistSourceID?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistIPRanges: values.whitelistIPRanges?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistBrands: values.whitelistBrands?.map((e) => e.trim()).filter(e => e.length > 0),
                whitelistCategories: values.categoriesIsBlack ? [] : values.categories?.map((e) => e.trim()).filter(e => e.length > 0),
                blacklistCategories: values.categoriesIsBlack ? values.categories?.map((e) => e.trim()).filter(e => e.length > 0) : [],
                whitelistCitiesIDs: values.whitelistCities?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistCountriesIDs: values.whitelistCountries?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistRegionsIDs: values.whitelistRegions?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistLanguageCodes: values.whitelistLanguageCodes?.map((e) => e.trim()).filter(e => e.length > 0),
                sourceAutorules: values.autorulesEnabled ? values.autorules?.map((e) => {
                  return {
                    clickhouseExp: generateRuleString(e.metrics),
                    days: e.days,
                  }
                }) : [],
              },
            },
          })

          if (response.data) {
            try {
              const currentCreatives = await client.query({
                query: QUERY_GET_CAMPAIGN_CREATIVES,
                variables: {
                  id: response.data!.updateCampaignFull.id,
                  pagination: {
                    pageNumber: 1,
                    pageSize: 200,
                  }
                }
              })
              if (currentCreatives.data) {
                // Check for new added creatives
                for (var i = 0; i < filteredExistsCreatives.length; i++) {
                  const isFound = currentCreatives.data.getCampaign.creatives.find(e => e.id === filteredExistsCreatives[i].id) !== undefined;
                  if (!isFound) {
                    await client.mutate({
                      mutation: MUTATION_ATTACH_CREATIVE,
                      variables: {
                        campaignID: id!,
                        creativeID: filteredExistsCreatives[i].id,
                      },
                    })
                  }
                }
              }

              // Check for deleted creatives
              for (var i = 0; i < currentCreatives.data!.getCampaign.creatives.length; i++) {
                const isFound = filteredExistsCreatives.find(e => e.id === currentCreatives.data!.getCampaign.creatives[i].id) !== undefined;
                if (!isFound) {
                  await client.mutate({
                    mutation: MUTATION_DETACH_CREATIVE,
                    variables: {
                      campaignID: id!,
                      creativeID: currentCreatives.data!.getCampaign.creatives[i].id,
                    },
                  })
                }
              }

            } catch (e) {

            }
          }
        }
        generateAlert('Successfully', AlertType.SUCCESS)
      } catch (e) {
        if (e instanceof ApolloError && e.graphQLErrors && e.message) {
          generateAlert(e.message, AlertType.ERROR);
        } else {
          generateAlert('Unknown error', AlertType.ERROR);
        }
      }
    }
  })

  useEffect(() => {
    if (bidChanged || id) return;
    switch (formik.values.type) {
      case CampaignType.Banner:
        formik.setFieldValue('bid', "0.1");
        break;
      case CampaignType.Pops:
        formik.setFieldValue('bid', "2");
        break;
      case CampaignType.Push:
        formik.setFieldValue('bid', "0.02");
        break;
    }
  }, [formik.values.type, bidChanged])

  useEffect(() => {
    if (!formik.values.type) return;

    setFilteredExistsCreatives(allExistsCreatives.filter(e => {
      switch (e.type) {
        case CreativeType.Banner:
          return formik.values.type === CampaignType.Banner;
        case CreativeType.Pops:
          return formik.values.type === CampaignType.Pops;
        case CreativeType.Push:
          return formik.values.type === CampaignType.Push;
      }
    }))
  }, [formik.values.type, allExistsCreatives])

  useEffect(() => {
    if (formik.values.whitelistCountries?.length === 0) {
      formik.setFieldValue('whitelistRegions', []);
      formik.setFieldValue('whitelistCities', []);
    }
  }, [formik.values.whitelistCountries])

  useEffect(() => {
    if (formik.values.whitelistRegions?.length === 0) {
      formik.setFieldValue('whitelistCities', []);
    }
  }, [formik.values.whitelistRegions])

  const bidMin = useCallback(() => {
    if (formik.values.type === CampaignType.Pops && formik.values.payType === PayType.Cpm) {
      return 0.1
    }
    if (formik.values.type === CampaignType.Push && formik.values.payType === PayType.Cpc) {
      return 0.01
    }
    if (formik.values.type === CampaignType.Banner && formik.values.payType === PayType.Cpm) {
      return 0.01
    }
  }, [formik.values.type, formik.values.payType])

  const bidMax = useCallback(() => {
    if (formik.values.type === CampaignType.Pops && formik.values.payType === PayType.Cpm) {
      return 20
    }
    if (formik.values.type === CampaignType.Push && formik.values.payType === PayType.Cpc) {
      return 1
    }
    if (formik.values.type === CampaignType.Banner && formik.values.payType === PayType.Cpm) {
      return 10
    }
  }, [formik.values.type, formik.values.payType])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: id ? 'MENU.EDITCAMPAIGN' : 'MENU.NEWCAMPAIGN' })}</PageTitle>
      <div className='col-xl-12'>
        <div className='card card-xxl-stretch mb-5 mb-xl-8'>
          <div className='card-body py-3 pt-8 container'>
            <form onSubmit={formik.handleSubmit} noValidate>
              {/* NAME */}
              <div className='mb-5'>
                <label className="required form-label">Name</label>
                <input
                  type="text"
                  className={
                    formik.touched.name && formik.errors.name
                      ? 'form-control form-control-solid is-invalid'
                      : 'form-control form-control-solid'
                  }
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>

              {/* ADVERTISER */}
              {currentUser?.role === UserRole.Admin && <>
                <div className="mb-5">
                  <label htmlFor="adv-id" className="required form-label">Advertiser</label>
                  <AdvPicker value={formik.values.adv} onChange={(u) => formik.setFieldValue('adv', u)} />

                  {formik.touched.adv && formik.errors.adv && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.adv.toString()}</div>
                    </div>
                  )}
                </div>
              </>}

              {/* AD TYPE */}
              {(<>
                <label className="form-label">Ad Types</label>
                <div className='row'>
                  {typeOptions.map((e) => {
                    return (
                      <div
                        className='col-lg-4'
                        key={e.value}
                        onClick={(_) => {
                          formik.setFieldValue('type', e.value);
                        }}
                      >
                        <label
                          className={clsx(
                            'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5',
                            { 'border-primary': e.value === formik.values.type },
                          )}
                        >
                          {e.icon}
                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{e.title}</span>
                            <span className='text-gray-400 fw-bold fs-6'>
                              {e.description}
                            </span>
                          </span>
                        </label>
                      </div>
                    );
                  })}

                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.type}</div>
                    </div>
                  )}
                </div>
              </>)}

              {/* PAY TYPE */}
              {formik.values.type && (
                <>
                  <label className="form-label">Pay Type</label>
                  <div className='row'>
                    {payOptions.filter((e) => {
                      const type = typeOptions.find((e) => e.value === formik.values.type);
                      if (!type) return false;
                      return type!.allowedPayOptions.indexOf(e.value) > -1;
                    }).map((e) => {
                      return (
                        <div className='col-lg-6' onClick={(_) => {
                          formik.setFieldValue('payType', e.value);
                          formik.setFieldValue('convTrack', e.value == PayType.Cpa);
                        }}
                          key={e.value}
                        >
                          <label
                            className={clsx(
                              'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5',
                              { 'border-primary': e.value === formik.values.payType },
                            )} >
                            {e.icon}

                            <span className='d-block fw-bold text-start'>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>{e.title}</span>
                              <span className='text-gray-400 fw-bold fs-6'>
                                {e.description}
                              </span>
                            </span>
                          </label>
                        </div>
                      );
                    })}

                    {formik.touched.payType && formik.errors.payType && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.payType}</div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* Whitelist categories */}
              {formik.values.payType && (
                <>
                  <label className='form-label'>Category</label>
                  <div
                    className='grid-container'
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, 200px) minmax(200px, max-content)',
                      gap: '10px',
                    }}
                  >
                    {categoryOptions.map((e) => (
                      <div
                        key={e.title}
                        className={`grid-item rounded-3`}
                        onClick={(_) => {
                          setCustomCategories(false);
                          formik.setFieldValue('categoriesIsBlack', e.isBlacklist);
                          formik.setFieldValue('categories', e.value);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <label
                          className={clsx(
                            'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center',
                            { 'border-primary': JSON.stringify(formik.values.categories) === JSON.stringify(e.value) && formik.values.categoriesIsBlack === e.isBlacklist && !customCategories },
                          )}
                          style={{ height: '70%' }}
                        >
                          <KTIcon iconName={e.icon} className='fs-3x me-5' />

                          <div className='text-block' style={{ flexGrow: 1 }}>
                            <span className='text-dark fw-bolder d-block fs-4'>{e.title}</span>
                          </div>
                        </label>
                      </div>
                    ))}

                    <div
                      className={`grid-item rounded-3`}
                      onClick={(_) => {
                        formik.setFieldValue('categories', []);
                        setCustomCategories(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <label
                        className={clsx(
                          'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center',
                          { 'border-primary': customCategories },
                        )}
                        style={{ height: '70%' }}
                      >
                        <KTIcon iconName='address-book' className='fs-3x me-5' />

                        <div className='text-block' style={{ flexGrow: 1 }}>
                          <span className='text-dark fw-bolder d-block fs-4'>Specific IAB category</span>
                        </div>
                      </label>
                    </div>

                    {formik.touched.categories && formik.errors.categories && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.categories}</div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* Whitelist categories custom selector */}

              {customCategories && (
                <div className='mb-5'>
                  <label className="form-label">Specific categories</label>
                  <CampaignCategoryMultiPicker value={formik.values.categories?.map((e) => {
                    return { id: e }
                  }) ?? []} onChange={(e) => {
                    formik.setFieldValue('categories', Array.from(e.values()).map(e => e.id))
                  }} />

                  {((formik.values.categories?.length ?? 0) > 0) && (
                    <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                      <label className="form-label me-5 mt-2" htmlFor="device_webview">
                        Blacklist
                      </label>
                      <input className="form-check-input h-20px w-30px" type="checkbox" id="device_webview"
                        checked={formik.values.categoriesIsBlack} onChange={(e) => {
                          formik.setFieldValue('categoriesIsBlack', !formik.values.categoriesIsBlack);
                        }} />
                    </div>
                  )}
                </div>
              )}

              {/* BID */}

              {(formik.values.categories && (formik.values.payType !== PayType.Cpa || currentUser?.role === UserRole.Admin)) &&
                <div className="mb-5 col-md-4 col-12">
                  <label htmlFor="d_bid" className="form-label">BID</label>
                  <input type="text" id="d_bid" name="d_bid" placeholder={(() => {
                    const max = bidMax();
                    const min = bidMin();
                    if (min && max) return `from ${min} to ${max}`
                    if (max) return `from ${max}`
                    if (min) return `to ${min}`
                    return 'Enter bid';
                  })()} className="form-control form-control-solid"
                    value={formik.values.bid} onChange={(e) => {
                      setBidChanged(true);
                      formik.setFieldValue("bid", e.target.value.replaceAll(',', '.'));
                    }} />

                  {(bidMax() && (parseFloat(formik.values.bid) ?? 0) > bidMax()!) && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Bid is greater than {bidMax()}</div>
                    </div>
                  )}

                  {(bidMin() && (parseFloat(formik.values.bid ?? 0)) < bidMin()!) && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>Bid is lower than {bidMin()}</div>
                    </div>
                  )}

                  {formik.touched.bid && formik.errors.bid && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.bid}</div>
                    </div>
                  )}
                </div>}

              {/* CONVERSION TRACKING */}
              {formik.values.categories && (<>
                <div className="form-check form-switch form-check-custom form-check-solid mt-2 mb-2">
                  <label className="form-label me-5 mt-2" htmlFor="conversion-tracking">
                    Conversion tracking
                  </label>
                  <input className="form-check-input h-20px w-30px" type="checkbox" id="conversion-tracking"
                    checked={formik.values.convTrack} disabled={formik.values.payType === PayType.Cpa} onChange={(e) => {
                      formik.setFieldValue('convTrack', !formik.values.convTrack);
                    }} />

                  {formik.touched.convTrack && formik.errors.convTrack && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.convTrack}</div>
                    </div>
                  )}
                </div>
                {formik.values.convTrack && <>
                  <div className='mt-2 alert alert-warning'>
                    <div className='align-items-top' style={{ display: 'flex' }}>
                      <KTIcon iconName="information" className="text-warning fs-2tx me-4" />
                      <div className="text-dark">
                        <b>Postback API links</b>
                        <div>
                          <OverlayTrigger overlay={
                            <Tooltip>Copy link</Tooltip>
                          }>
                            <CopyToClipboard
                              text={`http://cv.tor.energy/conv/?clickid={click_id}&bid={payout_conv}&t=1&aid=${formik.values.adv?.advertiserProfile.advertiserID ?? -1}`}
                              onCopy={() => generateAlert('Link copied', AlertType.SUCCESS)}
                            >
                              <b>
                                {`1) http://cv.tor.energy/conv/?clickid={click_id}&bid={payout_conv}&t=1&aid=${formik.values.adv?.advertiserProfile.advertiserID ?? -1}`}
                              </b>
                            </CopyToClipboard>
                          </OverlayTrigger>
                          <br />

                          <OverlayTrigger overlay={
                            <Tooltip>Copy link</Tooltip>
                          }>
                            <CopyToClipboard
                              text={`http://cv.tor.energy/conv/?clickid={click_id}&bid={payout_conv}&t=1&aid=${formik.values.adv?.advertiserProfile.advertiserID ?? -1}`}
                              onCopy={() => generateAlert('Link copied', AlertType.SUCCESS)}
                            >
                              <b>
                                {`2) http://cv.tor.energy/conv/?clickid={click_id}&bid={payout_conv}&t=2&aid=${formik.values.adv?.advertiserProfile.advertiserID ?? -1}`}
                              </b>
                            </CopyToClipboard>
                          </OverlayTrigger>
                          <br />
                          <span className="text-gray-600">
                            {`You should pass {click_id} in advertising links and return this value in the postback used macros from your tracking system.`}
                            <br />
                            <br />
                            {`You can use one or two levels conversion tracking, example:`}
                            <br />
                            {`Registration: http://cv.tor.energy/conv/?clickid=click_id_value&bid=0&t=1&aid=1`}
                            <br />
                            {`Deposit: http://cv.tor.energy/conv/?clickid=click_id_value&bid=100.00&t=2&aid=1`}
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>}
              </>)}

              {formik.values.payType && (<>
                <div className="mb-5">
                  <label htmlFor="cat_camp" className="required form-label">Offer Category</label>
                  <CampaignCategoryTypePicker value={{ name: formik.values.category }} onChange={(e) => {
                    formik.setFieldValue('category', e?.name);
                  }} />
                </div>
              </>)}

              {/* LIMITS */}

              <div className='mb-5'>
                <div className='accordion' id='kt_accordion_1'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header'>
                      <button
                        className='accordion-button fs-4 fw-bold'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_limits'
                        aria-expanded='true'
                        aria-controls='kt_accordion_1_body_limits'
                        ref={accordionRefs.limits}
                      >
                        Limits
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_limits'
                      className='accordion-collapse show'
                      aria-labelledby='kt_accordion_1_header_limits'
                      data-bs-parent='#kt_accordion_1'>
                      <div className='accordion-body container'>
                        <div className="row mt-5">
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_uniq" className="form-label">Capping</label>
                            <input type="text" id="d_limit_uniq" name="d_limit_uniq" className="form-control form-control-solid"
                              value={formik.values.dailyLimitUniq === 0 ? '' : formik.values.dailyLimitUniq} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitUniq', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />
                          </div>
                          <div className="mb-5 col-md-8 col-12" />
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_cls" className="form-label">Daily Limit Clicks</label>
                            <input type="text" id="d_limit_cls" name="d_limit_cls" className="form-control form-control-solid"
                              value={formik.values.dailyLimitCls === 0 ? '' : formik.values.dailyLimitCls} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitCls', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.dailyLimitCls && formik.errors.dailyLimitCls && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.dailyLimitCls}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_imp" className="form-label">Daily Limit Impressions</label>
                            <input type="text" id="d_limit_imp" name="d_limit_imp" className="form-control form-control-solid"
                              value={formik.values.dailyLimitImp === 0 ? '' : formik.values.dailyLimitImp} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitImp', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.dailyLimitImp && formik.errors.dailyLimitImp && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.dailyLimitImp}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_cost" className="form-label">Daily Limit Cost</label>
                            <input type="text" id="d_limit_cost" name="d_limit_cost" className="form-control form-control-solid"
                              value={formik.values.dailyLimitCost === 0 ? '' : formik.values.dailyLimitCost} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitCost', isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value));
                              }} />

                            {formik.touched.dailyLimitCost && formik.errors.dailyLimitCost && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.dailyLimitCost}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="t_limit_cls" className="form-label">Total Limit Clicks</label>
                            <input type="text" id="t_limit_cls" name="t_limit_cls" className="form-control form-control-solid"
                              value={formik.values.totalLimitCls === 0 ? '' : formik.values.totalLimitCls} min={0} onChange={(e) => {
                                formik.setFieldValue('totalLimitCls', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.totalLimitCls && formik.errors.totalLimitCls && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.totalLimitCls}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="t_limit_imp" className="form-label">Total Limit Impressions</label>
                            <input type="text" id="t_limit_imp" name="t_limit_imp" className="form-control form-control-solid"
                              value={formik.values.totalLimitImp === 0 ? '' : formik.values.totalLimitImp} min={0} onChange={(e) => {
                                formik.setFieldValue('totalLimitImp', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.totalLimitImp && formik.errors.totalLimitImp && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.totalLimitImp}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="t_limit_cost" className="form-label">Total Limit Cost</label>
                            <input type="text" id="t_limit_cost" name="t_limit_cost" className="form-control form-control-solid"
                              value={formik.values.totalLimitCost === 0 ? '' : formik.values.totalLimitCost} min={0} onChange={(e) => {
                                formik.setFieldValue('totalLimitCost', isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value));
                              }} />

                            {formik.touched.totalLimitCost && formik.errors.totalLimitCost && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.totalLimitCost}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ADMIN */}
                  {currentUser?.role === UserRole.Admin &&
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='kt_accordion_1_header_admin'>
                        <button
                          className='accordion-button fs-4 fw-bold collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#kt_accordion_1_body_admin'
                          aria-expanded='false'
                          aria-controls='kt_accordion_1_body_admin'
                          ref={accordionRefs.admin}
                        >
                          Admin
                        </button>
                      </h2>
                      <div
                        id='kt_accordion_1_body_admin'
                        className='accordion-collapse collapse'
                        aria-labelledby='kt_accordion_1_header_admin'
                        data-bs-parent='#kt_accordion_1'
                      >
                        <div className='accordion-body'>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_roi" className="form-label">ROI</label>
                            <input type="text" id="d_roi" name="d_roi" className="form-control form-control-solid"
                              value={formik.values.roi} onChange={(e) => {
                                formik.setFieldValue('roi', e.target.value.replaceAll(',', '.'));
                              }} />

                            {formik.touched.roi && formik.errors.roi && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.roi}</div>
                              </div>
                            )}
                          </div>
                          <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                            <div className="form-check form-switch form-check-custom form-check-solid mt-2 mb-5">
                              <label className="form-label me-5 mt-2" htmlFor="bidder">
                                Bidder
                              </label>
                              <input className="form-check-input h-20px w-30px" type="checkbox" id="bidder"
                                checked={formik.values.bidder} onChange={(e) => {
                                  formik.setFieldValue('bidder', !formik.values.bidder);
                                }} />

                              {formik.touched.bidder && formik.errors.bidder && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.bidder}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                            <label className="form-label me-5 mt-2" htmlFor="machine-learning-ab">
                              Machine Learning AB Testing
                            </label>
                            <input className="form-check-input h-20px w-30px" type="checkbox" id="machine-learning-ab"
                              checked={formik.values.mlABtest} onChange={(e) => {
                                formik.setFieldValue('mlABtest', !formik.values.mlABtest);
                              }} />

                            {formik.touched.mlABtest && formik.errors.mlABtest && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.mlABtest}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {/* GEO & LANGUAGE */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_geo'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_geo'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_get'
                        ref={accordionRefs.geoLanguage}
                      >
                        Geo & Language
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_geo'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_geo'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <label htmlFor="countries" className="form-label">Country</label>
                        <CountryMultiPicker value={formik.values.whitelistCountries ?? null} onChange={(values) => {
                          formik.setFieldValue('whitelistCountries', values);
                        }} />

                        {(formik.values.whitelistCountries?.length ?? 0) > 0 &&
                          <div className="mt-5">
                            <label htmlFor="regions" className="form-label">Regions</label>
                            <RegionMultiPicker
                              countryIDs={formik.values.whitelistCountries!.map(e => e.id)}
                              value={formik.values.whitelistRegions ?? []}
                              onChange={(e) => formik.setFieldValue('whitelistRegions', Array.from(e.values()))}
                            />
                          </div>
                        }

                        {(formik.values.whitelistRegions?.length ?? 0) > 0 &&
                          <div className="mt-5">
                            <label htmlFor="cities" className="form-label">Cities</label>
                            <CityMultiPicker
                              regionIDs={formik.values.whitelistRegions!.map((e) => e.id)}
                              value={formik.values.whitelistCities ?? []}
                              onChange={(e) => formik.setFieldValue('whitelistCities', Array.from(e.values()))}
                            />
                          </div>
                        }


                        <div className='mt-5'>
                          <label htmlFor='languages' className='form-label'>Languages</label>
                          <LanguageMultiPicker
                            value={formik.values.whitelistLanguageCodes ?? []}
                            onChange={(e) => formik.setFieldValue('whitelistLanguageCodes', Array.from(e.values()).map(e => e.code))}
                          />
                        </div>
                        {/* <div className="mt-5">
                          <label htmlFor="lang" className="form-label"> Language </label>
                          <Select
                            options={languageList.map((item): SelectObj => {
                              return { value: item.code, label: item.name }
                            })}
                            id="lang"
                            name="type-select"
                            className="react-select"
                            classNamePrefix="select"
                            value={campaign.lang.length === 0 ? null : {
                              label: (languageList as any[]).find((e) => e.code === campaign.lang)?.name,
                              value: (languageList as any[]).find((e) => e.code === campaign.lang)?.code,
                            }}
                            onChange={(e: SingleValue<SelectObj>) => {
                              if (e !== null) {
                                setCampaign({ ...campaign, lang: e.value })
                              }
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* DEVICES */}
                  <DevicesTile
                    exec={formik.values.whitelistExec}
                    onChanged={(exec) => formik.setFieldValue('whitelistExec', exec)}
                    accordionRef={accordionRefs.device}
                  />
                  {formik.touched.whitelistExec && formik.errors.whitelistExec && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.whitelistExec}</div>
                    </div>
                  )}

                  {/* AUTORULES  */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_auto_rules'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_auto_rules'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_auto_rules'
                      >
                        Auto Rules
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_auto_rules'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_auto_rules'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className="form-check form-switch form-check-custom form-check-solid mt-2 ms-auto">
                          <label className="form-label me-5 mt-2" htmlFor="auto_rules">
                            Auto Rules
                          </label>
                          <input
                            className="form-check-input h-20px w-30px"
                            type="checkbox"
                            id="auto_rules"
                            checked={formik.values.autorulesEnabled}
                            onChange={() => formik.setFieldValue('autorulesEnabled', !formik.values.autorulesEnabled)}
                          />
                        </div>

                        {formik.values.autorulesEnabled && (
                          <div id='kt_account_profile_details' className='collapse show'>
                            <RuleComponent
                              rules={formik.values.autorules ?? []}
                              onRulesChanged={(rules) => formik.setFieldValue('autorules', rules)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* PUSH SUBSCRIPTION */}
                  {formik.values.type === 'push' &&
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='kt_accordion_1_header_push'>
                        <button
                          className='accordion-button fs-4 fw-bold collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#kt_accordion_1_body_push'
                          aria-expanded='false'
                          aria-controls='kt_accordion_1_body_push'
                          ref={accordionRefs.pushSubs}
                        >
                          Push Subscription time
                        </button>
                      </h2>
                      <div
                        id='kt_accordion_1_body_push'
                        className='accordion-collapse collapse'
                        aria-labelledby='kt_accordion_1_header_push'
                        data-bs-parent='#kt_accordion_1'
                      >
                        <div className='accordion-body'>
                          <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="subs_ttl" className="form-label">Subscription TTL</label>
                              <input type="number" id="subs_ttl" name="subs_ttl" className="form-control form-control-solid"
                                value={formik.values.subsTTL} onChange={(e) => {
                                  formik.setFieldValue('subsTTL', e.target.valueAsNumber);
                                }} />
                              {' Time in seconds, e.g.: 86400 - display to recent subscribers within the last 24 hours'}

                              {formik.touched.subsTTL && formik.errors.subsTTL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.subsTTL}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {/* SOURCE */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_3'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_3'
                        ref={accordionRefs.source}
                      >
                        Source
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_3'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_3'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className="mb-5">
                          <label htmlFor="sourceID" className="form-label">Whitelist source ID</label>
                          <textarea id="sourceID" name="Source ID" className="form-control form-control-solid"
                            placeholder="Please enter source id with any separator. Example: 123,456,789"
                            value={formik.values.whitelistSourceID} onChange={(e) => {
                              formik.setFieldValue('whitelistSourceID', e.target.value);
                            }} />

                          {formik.touched.whitelistSourceID && formik.errors.whitelistSourceID && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.whitelistSourceID}</div>
                            </div>
                          )}
                        </div>
                        <div className="mb-5">
                          <label htmlFor="sourceSSP" className="form-label">Whitelist source SSP</label>
                          <textarea id="sourceSSP" name="Source SSP" className="form-control form-control-solid"
                            placeholder="Please enter ssp with any separator. Example: 123,456,789"
                            value={formik.values.whitelistSSP} onChange={(e) => {
                              formik.setFieldValue('whitelistSSP', e.target.value);
                            }} />

                          {formik.touched.whitelistSSP && formik.errors.whitelistSSP && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.whitelistSSP}</div>
                            </div>
                          )}
                        </div>

                        <div className="mb-5">
                          <label htmlFor="blacklistSourceID" className="form-label">Source disabled ID</label>
                          <textarea id="blacklistSourceID" name="Source disabled ID" className="form-control form-control-solid"
                            placeholder="Please enter source id with any separator. Example: 123,456,789"
                            value={formik.values.blacklistSourceID} onChange={(e) => {
                              formik.setFieldValue('blacklistSourceID', e.target.value);
                            }} />

                          {formik.touched.blacklistSourceID && formik.errors.blacklistSourceID && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.blacklistSourceID}</div>
                            </div>
                          )}
                        </div>
                        <div className="mb-5">
                          <label htmlFor="blacklistSourceSSP" className="form-label">Source disabled SSP</label>
                          <textarea id="blacklistSourceSSP" name="Source disabled SSP" className="form-control form-control-solid"
                            placeholder="Please enter ssp with any separator. Example: 123,456,789"
                            value={formik.values.blacklistSSP} onChange={(e) => {
                              formik.setFieldValue('blacklistSSP', e.target.value);
                            }} />

                          {formik.touched.blacklistSSP && formik.errors.blacklistSSP && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.blacklistSSP}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_connection'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_connection'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_connection'
                        ref={accordionRefs.connection}
                      >
                        Connection
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_connection'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_connection'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <label htmlFor="type" className="form-label">Connection Type</label>
                        <Select
                          options={connectionOptions}
                          id="type"
                          name="type-select"
                          className="react-select"
                          classNamePrefix="select"
                          value={connectionOptions.find((e) => e.value === formik.values.connectionType)}
                          onChange={(e: SingleValue<{ label: string, value: ConnectionType }>) => {
                            if (e !== null) {
                              formik.setFieldValue('connectionType', e.value);
                            }
                          }}
                        />
                        <label htmlFor="mobilecarriers" className="form-label mt-5">Mobile carriers</label>
                        <MobileCarrierMultiPicker value={formik.values.whitelistBrands?.map(e => {
                          return { name: e };
                        }) ?? null} onChange={(e) => {
                          formik.setFieldValue('whitelistBrands', Array.from(e.map(e => e.name)));
                        }} countryIDs={formik.values.whitelistCountries?.map(e => e.id)} />
                        <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                          <label className="form-label me-5 mt-2" htmlFor="proxy_filter">
                            All proxy disable
                          </label>
                          <input className="form-check-input h-20px w-30px" type="checkbox" id="proxy_filter"
                            checked={formik.values.disableProxy} onChange={(e) => {
                              formik.setFieldValue('disableProxy', !formik.values.disableProxy);
                            }} />
                        </div>
                        <div className="mt-5">
                          <label htmlFor="ipwhitelist" className="form-label">IP enabled range</label>
                          <textarea id="ipwhitelist" name="IP enabled range" className="form-control form-control-solid"
                            placeholder="0.0.0.0/0, 192.168.0.1/0"
                            value={formik.values.whitelistIPRanges} onChange={(e) => {
                              formik.setFieldValue('whitelistIPRanges', e.target.value);
                            }} />
                        </div>
                        <div className="mt-5">
                          <label htmlFor="ipwblacklist" className="form-label">IP disabled range</label>
                          <textarea id="ipwblacklist" name="IP disabled range" className="form-control form-control-solid"
                            placeholder="0.0.0.0/0, 192.168.0.1/0"
                            value={formik.values.blacklistIPRanges} onChange={(e) => {
                              formik.setFieldValue('blacklistIPRanges', e.target.value);
                            }} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* AI SETTING */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_ai_setting'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_ai_setting'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_ai_setting'
                        ref={accordionRefs.aiSetting}
                      >
                        AI Setting
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_ai_setting'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_ai_setting'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                          <label className="form-label me-5 mt-2" htmlFor="machine-learning">
                            Machine Learning
                          </label>
                          <input className="form-check-input h-20px w-30px" type="checkbox" id="machine-learning"
                            checked={formik.values.mlConv} onChange={(e) => {
                              formik.setFieldValue('mlConv', !formik.values.mlConv);
                            }} />

                          {formik.touched.mlConv && formik.errors.mlConv && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.mlConv}</div>
                            </div>
                          )}
                        </div>
                        <div className="my-3">
                          <label htmlFor="ml_conv_min" className="form-label">Min. conversions for AI learning</label>
                          <input type="number" id="ml_conv_min" name="ml_conv_min" disabled={currentUser?.role !== UserRole.Admin} className="form-control form-control-solid"
                            value={formik.values.mlConvMin} onChange={(e) => {
                              formik.setFieldValue('mlConvMin', e.target.valueAsNumber);
                            }} />

                          {formik.touched.mlConvMin && formik.errors.mlConvMin && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.mlConvMin}</div>
                            </div>
                          )}
                        </div>
                        <div className="my-3">
                          <label htmlFor="ml_imps_step" className="form-label">Update every (input) clicks</label>
                          <input type="number" id="ml_imps_step" name="ml_imps_step" className="form-control form-control-solid"
                            value={formik.values.mlImpsStep} onChange={(e) => {
                              formik.setFieldValue('mlImpsStep', e.target.valueAsNumber);
                            }} />

                          {formik.touched.mlImpsStep && formik.errors.mlImpsStep && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.mlImpsStep}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* OTHER */}
                  {currentUser?.role === UserRole.Admin && (
                    <>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_other'>
                          <button
                            className='accordion-button fs-4 fw-bold collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_accordion_1_body_other'
                            aria-expanded='false'
                            aria-controls='kt_accordion_1_body_other'
                            ref={accordionRefs.other}
                          >
                            Other
                          </button>
                        </h2>
                        <div
                          id='kt_accordion_1_body_other'
                          className='accordion-collapse collapse'
                          aria-labelledby='kt_accordion_1_header_other'
                          data-bs-parent='#kt_accordion_1'
                        >
                          <div className='accordion-body'>
                            <div className="my-3">
                              <label htmlFor="last_model_imps" className="form-label">Last model impressions</label>
                              <input type="number" id="last_model_imps" name="last_model_imps" className="form-control form-control-solid"
                                value={formik.values.lastModelImps} onChange={(e) => {
                                  formik.setFieldValue('lastModelImps', e.target.valueAsNumber);
                                }} />

                              {formik.touched.lastModelImps && formik.errors.lastModelImps && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.lastModelImps}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <CreativesTable
                className='bg-secondary'
                campaignType={initialValues.type!}
                creatives={filteredExistsCreatives}
                createButtonTitle='Select creative'
                onAttach={(creative) => {
                  setFilteredExistsCreatives([...filteredExistsCreatives, creative]);
                }}
                onDetach={(creative) => [
                  setFilteredExistsCreatives(filteredExistsCreatives.filter(e => e.id != creative.id))
                ]}
              />
              {(canCreateCreative && formik.values.type) && <>
                <div className='accordion-body row p-4'>
                  <div className='text-block' style={{ flexGrow: 1 }}>
                    <span className='text-dark fw-bolder d-block fs-4 mb-2'>Creatives</span>
                  </div>
                  <div className='card-body border-top p-4'>
                    {creatives.map((creative, index) => {
                      var creativeType: CreativeType | undefined;
                      switch (formik.values.type) {
                        case CampaignType.Banner:
                          creativeType = CreativeType.Banner;
                          break;
                        case CampaignType.Pops:
                          creativeType = CreativeType.Pops;
                          break;
                        case CampaignType.Push:
                          creativeType = CreativeType.Push;
                          break;
                      }
                      return (
                        <div className={clsx('d-flex flex-row', { 'border-top': index === 0 })}>
                          <div className='d-flex flex-grow-1 flex-column pe-5'>
                            <div>
                              <CreativeControl
                                key={creative.internalID}
                                initial={creative}
                                type={creativeType!}
                                onChanged={(updated) => {
                                  setCreatives(
                                    creatives.map((c) => c.internalID === creative.internalID ? { ...updated, internalID: c.internalID } : c
                                    ))
                                }} />
                            </div>
                            <div className='my-3 d-flex flex-row align-items-left'>
                              <button type="button" className="btn btn-danger ms-2 mb-4" onClick={(event) => {
                                event.preventDefault();
                                setCreatives(creatives.filter((a) => a.internalID !== creative.internalID))
                              }}>Delete</button>
                            </div>
                          </div>
                          {creativeType === 'banner' && (
                            <div className=''>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>Preview</span>
                              <iframe height={250} width={300} scrolling="no" style={{ overflow: 'hidden' }} src={toAbsoluteUrl(`/template/creative?name=${encodeURIComponent(creative.name)}&camp=${encodeURIComponent(creative.bannerImageUrl)}&click_url=${btoa(encodeURIComponent(creative.rurl))}&desc=${encodeURIComponent(creative.bannerDescription)}&title=${encodeURIComponent(creative.bannerTitle)}`)} />
                            </div>
                          )}
                          {creativeType === 'pops' && (
                            <div className=''>
                            </div>
                          )}
                          {creativeType === 'push' && (
                            <div className=''>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>Preview</span>
                              <div className='w-300px h-250px'>
                                <PushPreview
                                  title={creative.pushTitle}
                                  description={creative.pushDescription}
                                  site={creative.rurl}
                                  logo={creative.pushIconUrl} />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div>
                      <button type="button" className="btn btn-primary ms-2 me-4" onClick={(e) => {
                        e.preventDefault();
                        setCreatives([...creatives, {
                          bannerDescription: "",
                          bannerImageUrl: "",
                          bannerSize: "",
                          bannerTitle: "",
                          name: "",
                          pushDescription: "",
                          pushIconUrl: "",
                          pushImageUrl: "",
                          pushTitle: "",
                          rurl: "",
                          internalID: getUniqueIdWithPrefix("creative")
                        }])
                      }}>Add new creative</button>
                    </div>
                  </div>
                </div>
              </>}

              <div className='d-flex'>
                <button
                  type='submit'
                  className='btn btn-success mt-2 me-2 px-6'
                >
                  Save
                </button>
              </div>
            </form>
          </div >
        </div >
      </div >
    </>
  )
}

const DevicesTile = ({ exec, onChanged, accordionRef }: { exec: String | undefined, onChanged: (exec: String) => void, accordionRef: React.RefObject<HTMLButtonElement> }) => {
  const parsedForm = useMemo(() => {
    if (exec && exec.length > 0) return decodeFilters(exec)
    return null;
  }, [exec]);

  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceTypeMultiResult[]>(parsedForm?.DeviceTypes ?? []);
  const [selectedDeviceOss, setSelectedDeviceOss] = useState<VersionedFilter<DeviceOSMultiResult>[]>(parsedForm?.OSFilters ?? []);
  const [selectedDeviceBrowsers, setSelectedDeviceBrowsers] = useState<VersionedFilter<DeviceBrowserMultiResult>[]>(parsedForm?.Browser ?? [])
  const [useCustomOsVersions, setUseCustomOsVersions] = useState<boolean>(parsedForm?.OSFilters.find((e) => e.useVersionFilter) !== undefined);
  const [useCustomBrowserVersion, setUseCustomBrowserVersion] = useState<boolean>(parsedForm?.Browser.find((e) => e.useVersionFilter) != undefined);
  const [blockDevicesWebView, setBlockDevicesWebView] = useState<boolean>(parsedForm?.WebView ?? false);

  useEffect(() => {
    setSelectedDeviceOss((e) => e.map((e) => ({ ...e, useVersionFilter: useCustomOsVersions })))
  }, [useCustomOsVersions])

  useEffect(() => {
    setSelectedDeviceBrowsers((e) => e.map((e) => ({ ...e, useVersionFilter: useCustomBrowserVersion })))
  }, [useCustomBrowserVersion])

  useEffect(() => {
    const encodedFilterOss = encodeFilters(selectedDeviceOss)
    const encodedFilterBrowsers = encodeFilters(selectedDeviceBrowsers)
    const encodedFilterTypes =
      selectedDeviceTypes.length === 0
        ? 'true'
        : `${selectedDeviceTypes
          .map((e) => {
            const isDesktop = e.name === 'desktop'
            return isDesktop ? 'desktop == true' : 'mobile == true'
          })
          .join(' || ')}`
    const hash: EncodedFiltersHash = {
      OSFilters: selectedDeviceOss,
      Browser: selectedDeviceBrowsers,
      WebView: blockDevicesWebView,
      DeviceTypes: selectedDeviceTypes,
    }
    const isEmptyFilter = [
      encodedFilterOss,
      encodedFilterBrowsers,
      encodedFilterTypes,
    ].every((e) => e === 'true') && !blockDevicesWebView
    const devicesFilter = isEmptyFilter ? '' : `${blockDevicesWebView ? 'wv == false' : 'true'} && (${encodedFilterTypes}) && (${encodedFilterOss} && ${encodedFilterBrowsers}) /*${JSON.stringify(
      hash
    )}*/`
    onChanged(devicesFilter);
  }, [selectedDeviceOss, selectedDeviceBrowsers, selectedDeviceTypes, blockDevicesWebView])

  return (
    <>
      <div className='accordion-item'>
        <h2 className='accordion-header' id='kt_accordion_1_header_2'>
          <button
            className='accordion-button fs-4 fw-bold collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_1_body_2'
            aria-expanded='false'
            aria-controls='kt_accordion_1_body_2'
            ref={accordionRef}
          >
            Device
          </button>
        </h2>
        <div
          id='kt_accordion_1_body_2'
          className='accordion-collapse collapse'
          aria-labelledby='kt_accordion_1_header_2'
          data-bs-parent='#kt_accordion_1'
        >
          <div className='accordion-body'>
            <div className="mb-5">
              <label htmlFor="devicetypes" className="form-label">Device types</label>
              <DeviceTypeMultiPicker
                value={selectedDeviceTypes}
                onChange={(e) => setSelectedDeviceTypes(Array.from(e.values()))}
              />
              <label htmlFor="deviceoss" className="form-label mt-4">Device OS</label>
              <DeviceOSMultiPicker
                value={selectedDeviceOss.map((e) => e.frontendValue)}
                onChange={(e) => {
                  setSelectedDeviceOss(e.map((e) => {
                    return {
                      versionOperator: '>=',
                      filterKey: 'os',
                      versionKey: 'osv',
                      useVersionFilter: false,
                      versionValue: 0,
                      frontendValue: e,
                      filterValue: e.name.toLowerCase(),
                    }
                  }))
                }}
              />
              <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                <label className="form-label mt-2" htmlFor="specific_os_version">
                  Any OS version
                </label>
                <input className="form-check-input h-20px w-30px mx-4" type="checkbox" id="specific_os_version"
                  checked={useCustomOsVersions} onChange={(e) => {
                    setUseCustomOsVersions(!useCustomOsVersions);
                  }} />
                <label className="form-label mt-2" htmlFor="specific_os_version">
                  Specific OS version
                </label>
              </div>
              {useCustomOsVersions && <div className="form-check-solid row">
                {selectedDeviceOss.map((e) => (<div className="mt-2 row" key={e.frontendValue.id}>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={[]}
                      id="type"
                      isDisabled={true}
                      value={{ label: e.frontendValue.name, value: 'value' }}
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={operatorOptions}
                      id="type"
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                      value={{ label: e.versionOperator, value: e.versionOperator }}
                      onChange={(event: SingleValue<{ label: string, value: string }>) => {
                        setSelectedDeviceOss(selectedDeviceOss.map((os) => {
                          if (os.frontendValue.id === e.frontendValue.id && event != null) {
                            return {
                              ...os,
                              versionOperator: event.value,
                            }
                          }
                          return os;
                        }))
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <input type="number" className="form-control form-control-solid"
                      value={e.versionValue} onChange={(event) => {
                        setSelectedDeviceOss(selectedDeviceOss.map((os) => {
                          if (os.frontendValue.id === e.frontendValue.id) {
                            return {
                              ...os,
                              versionValue: event.target.valueAsNumber,
                            }
                          }
                          return os;
                        }))
                      }} />
                  </div>
                </div>))}
              </div>}
              <div className="mt-5">
                <label htmlFor="devicebrowsers" className="form-label">Device Browsers</label>
                <DeviceBrowserMultiPicker
                  value={selectedDeviceBrowsers.map((e) => e.frontendValue)}
                  onChange={(e) => {
                    setSelectedDeviceBrowsers(e.map((e) => {
                      return {
                        versionOperator: '>=',
                        filterKey: 'browser',
                        versionKey: 'browser_v',
                        useVersionFilter: false,
                        versionValue: 0,
                        frontendValue: e,
                        filterValue: e.name.toLowerCase(),
                      }
                    }));
                  }}
                />
              </div>
              <div className="mt-5 form-check form-switch form-check-custom form-check-solid mt-2">
                <label className="form-label mt-2" htmlFor="specific_browser_version">
                  Any browser version
                </label>
                <input className="form-check-input h-20px w-30px mx-4" type="checkbox" id="specific_browser_version"
                  checked={useCustomBrowserVersion} onChange={(e) => {
                    setUseCustomBrowserVersion(!useCustomBrowserVersion);
                  }} />
                <label className="form-label mt-2" htmlFor="specific_browser_version">
                  Specific Browser version
                </label>
              </div>
              {useCustomBrowserVersion && <div className="form-check-solid row">
                {selectedDeviceBrowsers.map((e) => (<div className="mt-2 row" key={e.frontendValue.id}>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={[]}
                      id="type"
                      isDisabled={true}
                      value={{ label: e.frontendValue.name, value: 'value' }}
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={operatorOptions}
                      id="type"
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                      value={{ label: e.versionOperator, value: e.versionOperator }}
                      onChange={(event: SingleValue<{ label: string, value: string }>) => {
                        setSelectedDeviceBrowsers(selectedDeviceBrowsers.map((browser) => {
                          if (browser.frontendValue.id === e.frontendValue.id && event != null) {
                            return {
                              ...browser,
                              versionOperator: event.value,
                            }
                          }
                          return browser;
                        }))
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <input type="number" className="form-control form-control-solid"
                      value={e.versionValue} onChange={(event) => {
                        setSelectedDeviceBrowsers(selectedDeviceBrowsers.map((browser) => {
                          if (browser.frontendValue.id === e.frontendValue.id) {
                            return {
                              ...browser,
                              versionValue: event.target.valueAsNumber,
                            }
                          }
                          return browser;
                        }))
                      }} />
                  </div>
                </div>))}
              </div>}
            </div>
            <div className="form-check form-switch form-check-custom form-check-solid mt-2">
              <label className="form-label me-5 mt-2" htmlFor="device_webview">
                WebView disabled
              </label>
              <input className="form-check-input h-20px w-30px" type="checkbox" id="device_webview"
                checked={blockDevicesWebView} onChange={(e) => {
                  setBlockDevicesWebView(!blockDevicesWebView)
                }} />
            </div>
          </div>
        </div >
      </div>
    </>
  );
}